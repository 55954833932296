import { get } from './get';

export function maxByReducer(prop: string) {
  return function maxBy<T>(accum: T, obj: T): T {
    return get(accum, prop) >= get(obj, prop) ? accum : obj;
  };
}

export function maxWithReducer<T>(func: (el: T) => number) {
  return function maxWith(accum: T, obj: T): T {
    return func(accum) > func(obj) ? accum : obj;
  };
}
